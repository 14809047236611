<template>
  <div class="home">
    <div class="content">
      <h1 class="logo-type">RaiOS</h1>
      <h2 style="margin-left: 0.5rem; margin-right: 0.5rem;">A Python framework for real-life work automation</h2>
      <div class="buttons">
        <a href="https://github.com/raios-ai/raios" target="_blank" class="button raios-button">
          <div class="overlay"></div> 
          <div class="logo-title">
            <img class="logo-img" src="@/assets/white-github-logo.png" alt="GitHub Logo">
            <span>RaiOS</span>
          </div>
          <p>Robot AI Operating System.</p>
        </a>
        <a href="https://github.com/raios-ai/quba" target="_blank" class="button quba-button">
          <div class="overlay"></div> 
          <div class="logo-title">
            <img class="logo-img" src="@/assets/white-github-logo.png" alt="GitHub Logo">
            <span>Quba</span>
          </div>
          <p>Opensource smart speaker.</p>
        </a>
        <a href="https://x.com/raios_ai" target="_blank" class="button x-button">
          <div class="overlay"></div> 
          <div class="logo-title">
            <img class="logo-img" src="@/assets/white-x-logo.png" alt="GitHub Logo">
            <span>Follow us</span>
          </div>
          <p>Stay connected with us.</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  text-align: center;
  color: white;
  background-color: black;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo-type {
  font-size: 5rem;
  font-weight: 900;
  margin-bottom: 0;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  padding: 20px;
  border: 2px solid white;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  width: 200px; /* Adjusted for better alignment */
  overflow: hidden;
  text-align: left; /* Align text to the left */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.button:hover {
  transform: none;  /* Remove the moving up effect */
  box-shadow: none; /* Remove the shadow effect */
  border: 2px solid grey; /* Change border color to black on hover */
}

.raios-button {
  background-image: url('@/assets/bg-raios.png');
}

.quba-button {
  background-image: url('@/assets/bg-quba.png');
}

.x-button {
  background-image: url('@/assets/bg-x.png');
}

.logo-title {
  display: flex;
  align-items: center; /* Align items to the left */
}

.logo-img {
  width: 30px; /* Adjust size as needed */
  height: auto;
  margin-right: 5px;
}

.logo-title span {
font-size: 1rem;
color: white;
}

.button p {
  font-size: 0.8rem;
  color: white;
  margin-top: 5px; /* Adjust spacing as needed */
  align-self: stretch;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent gray overlay */
  z-index: 1; /* Ensure overlay is below the text and logo */
}

.logo-title, .button p {
  z-index: 2; /* Ensure text and logo are above the overlay */
  position: relative; /* Add this line */
}

/* Make the buttons on column if media size is less than 600px */
@media (max-width: 1200px) {
  .buttons {
    flex-direction: column;
  }

  .content {
    padding: 3rem 3rem;
    height: 100vh;
  }

  .button {
    width: 100%;
  }
}

@media (max-width: 600px) {
  h2 {
    width: 80%;
  }
  .buttons {
    width: 70%;
  }
}
</style>
