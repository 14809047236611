import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: function () {
      return import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue');
    }
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: function () {
      return import(/* webpackChunkName: "terms-of-service" */ '../views/TermsOfService.vue');
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
