<template>
    <footer class="footer">
      <div class="container">
        <div class="left">
          <a style="margin-left: 0px !important;" href="mailto:info@raios.ai">info@raios.ai</a>
        </div>
        <div class="right">
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/terms-of-service">Terms of Service</router-link>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
    // Component logic goes here
  }
  </script>
  
  <style scoped>
  .footer {
    z-index: 100;
    position: fixed;
    width: 100%;
    margin-bottom: 0px;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: black; /* Change to desired background color */
  }
  
  .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
  }

  .left, .right {
    display: flex;
    align-items: center;
  }
  
  .footer a {
    margin-left: 15px; /* Spacing between links */
    text-decoration: none;
    color: white; /* Change to desired link color */
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  </style>
  