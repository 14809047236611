<template>
    <header class="header">
      <div class="container">
        <div class="left">
          <router-link style="margin-left: 0px !important;" class="upper-left-logo" to="/">R</router-link>
        </div>
        <div class="right">
          <a href="https://github.com/raios-ai" target="_blank">Github</a>
          <span style="color: white; margin-left: 15px;">/</span>
          <a href="https://x.com/raios_ai" target="_blank">X</a>
        </div>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: 'HeaderComponent'
    // Component logic goes here
  }
  </script>
  
  <style scoped>
  .header {
    position: fixed;
    width: 100%;
    margin-top: 0px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: black; /* Change to desired background color */
  }

  .upper-left-logo {
    font-size: 30px;
    font-weight: bold;
    color: white;
    text-decoration: none;
  }
  
  .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
  }
  
  .left, .right {
    display: flex;
    align-items: center;
  }
  
  .header a {
    margin-left: 15px; /* Spacing between links */
    text-decoration: none;
    color: white; /* Change to desired link color */
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  </style>
  